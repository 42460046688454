import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./styles/style.scss";
import "react-accessible-accordion/dist/fancy-example.css";
import DateTimeDisplay from "./DateTimeDisplay";
import { useCountdown } from "./hooks/useCountdown";
import ReactGA from "react-ga4";
ReactGA.initialize("G-NR7607T1RC");

const NOW_IN_MS = new Date(Date.UTC(2024, 9, 10, 13, 0, 0)).getTime();
//const NOW_IN_MS = new Date().getTime() + 2;
const dateTimeAfterThreeDays = NOW_IN_MS;

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 7px;
  border: none;
  background-color: #651010;
  padding: 10px;
  font-weight: 700;
  font-size: 15px;
  color: var(--secondary-text);
  width: 250px;
  height: auto;
  cursor: pointer;
  :hover {
    background-color: #a71111;
  }
  :disabled {
    cursor: no-drop;
    background-color: grey !important;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  margin-top: 20px;
  margin-bottom: 10px;
  width: 439px;
  height: 76px;
  transition: width 0.5s;
  transition: height 0.5s;
  @media (max-width: 767px) {
    width: 400px;
  }
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 2px solid var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 250px;
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: white;
  text-decoration: none;
`;

export const StyledSocials = styled.img`
  margin-top: 30px;
  margin-bottom: 20px;
  width: 36px;
  height: 36px;
  opacity: 0.8;
  margin-right: 40px;
  @media (min-width: 767px) {
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledWeb = styled.img`
  margin-top: 30px;
  margin-bottom: 20px;
  width: 36px;
  height: 36px;
  opacity: 0.8;
  margin-right: 35px;
  @media (min-width: 767px) {
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [successFeedback, setSuccessFeedback] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="show-counter">
        <div href="" className="countdown-link">
          <DateTimeDisplay value={days} type={"Days"} isDanger={true} />
          <p style={{ color: "rgb(0 82 0)", marginTop: "-45px" }}>:</p>
          <DateTimeDisplay value={hours} type={"Hours"} isDanger={true} />
          <p style={{ color: "rgb(0 82 0)", marginTop: "-45px" }}>:</p>
          <DateTimeDisplay value={minutes} type={"Mins"} isDanger={true} />
          <p style={{ color: "rgb(0 82 0)", marginTop: "-45px" }}>:</p>
          <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={true} />
        </div>
      </div>
    );
  };

  const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
    if (days + hours + minutes + seconds <= 0) {
      setIsReady(true);
      return null;
    } else {
      return (
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
        />
      );
    }
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    setSuccessFeedback(false);
    setFeedback(`Pathogen Lab NFT mint process started. Please wait...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: null,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setSuccessFeedback(false);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setSuccessFeedback(true);
        setFeedback(
          `MINT COMPLETED SUCCESSFULLY! Follow the instructions on our Discord server for Passive Income System.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 30) {
      newMintAmount = 30;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        className="mainContainer"
        style={{
          padding: 0,
          backgroundColor: "var(--primary)",
          backgroundAttachment: "fixed",
        }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/ff.png" : null}
      >
        <ResponsiveWrapper className="mainWrapper" flex={1}>
          <div className="bodyContainer">
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
              className="firstContainer"
            >
              <StyledImg
                alt={"example"}
                src={"/config/images/1.png"}
                style={{ marginBottom: "50px" }}
              />
              <StyledImg alt={"example"} src={"/config/images/33.png"} />
            </s.Container>
            <s.Container
              flex={2}
              jc={"center"}
              ai={"center"}
              style={{
                justifyContent: "center",
              }}
              className="secondContainer"
            >
              <s.Container
                jc={"center"}
                ai={"center"}
                className="secondContent"
                style={{
                  backgroundColor: "rgb(255 255 255 / 92%)",
                  padding: 24,
                  borderRadius: 24,
                  boxShadow: "rgb(48 119 0) 0px 0px 20px 20px",
                  maxWidth: 800,
                  height: "90%",
                  maxHeight: "90%",
                }}
              >
                {/* <StyledImg src={"/config/images/gif.gif"} style={{ marginBottom: 20, width: 150, borderRadius: 20 }} /> */}
                <s.TextDescription className="phaseTitle">
                  PATHOGEN LAB
                </s.TextDescription>
                <s.TextDescription className="phaseSub">
                  VIRAL FURY
                </s.TextDescription>
                <CountdownTimer targetDate={dateTimeAfterThreeDays} />
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--primary-text)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* <span style={{color: 'var(--secondary)'}}>Contract Address:</span>
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
              {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink> */}
                </s.TextDescription>
                <s.SpacerSmall />
                {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "rgb(0 82 0)",
                        fontFamily: "'Kode Mono', cursive",
                        fontSize: "50px",
                        marginBottom: "10px",
                      }}
                    >
                      SOLD OUT
                    </s.TextDescription>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "rgb(0 82 0)",
                        fontFamily: "'Kode Mono', cursive",
                        fontSize: "20px",
                        marginBottom: "40px",
                      }}
                    >
                      You can still find {CONFIG.NFT_NAME} NFTs on
                      <StyledLink
                        target={"_blank"}
                        href={CONFIG.MARKETPLACE_LINK}
                      >
                        {" "}
                        {CONFIG.MARKETPLACE}
                      </StyledLink>
                      !
                    </s.TextDescription>
                  </>
                ) : (
                  <>
                    <div className="mintDetails">
                      <span style={{ color: "rgb(0 82 0)" }}>Mint Price </span>
                      <span style={{ color: "rgb(0 82 0)" }}>0.024 ETH</span>
                    </div>
                    {!blockchain.smartContract ? (
                      <div className="mintDetails">
                        <span style={{ color: "rgb(0 82 0)" }}>Supply </span>
                        <span style={{ color: "rgb(0 82 0)" }}>300</span>
                      </div>
                    ) : null}
                    {blockchain.smartContract ? (
                      <>
                        <div className="mintDetails">
                          <span style={{ color: "rgb(0 82 0)" }}>
                            Remeaning
                          </span>
                          <span style={{ color: "rgb(0 82 0)" }}>
                            {data.totalSupply == 0 ? (
                              <>0 / {CONFIG.MAX_SUPPLY - 900}</>
                            ) : (
                              <>
                                {data.totalSupply - 900} /{" "}
                                {CONFIG.MAX_SUPPLY - 900}
                              </>
                            )}
                          </span>
                        </div>
                      </>
                    ) : null}

                    {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        ></s.TextDescription>
                        <s.SpacerSmall />
                        <s.SpacerSmall />
                        <StyledButton
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}
                          className="connectButton"
                        >
                          Connect Wallet
                        </StyledButton>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.SpacerSmall />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "rgb(0 82 0)",
                              }}
                            >
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>
                    ) : (
                      <>
                        <div class="mintDetails">
                          <span style={{ color: "rgb(0 82 0)" }}>Quantity</span>
                          <div style={{ display: "flex" }}>
                            <StyledRoundButton
                              className="quantityButton"
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                decrementMintAmount();
                              }}
                            >
                              -
                            </StyledRoundButton>
                            <s.SpacerMedium />
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "rgb(0 82 0)",
                                fontSize: "20px",
                              }}
                            >
                              {mintAmount}
                            </s.TextDescription>
                            <s.SpacerMedium />
                            <StyledRoundButton
                              className="quantityButton"
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                              }}
                            >
                              +
                            </StyledRoundButton>
                          </div>
                        </div>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          <div
                            className={
                              (feedback == "" ? "" : "feedbackArea ") +
                              (successFeedback ? "feedbackSuccess" : "")
                            }
                            style={{ minHeight: "25px" }}
                          >
                            {feedback}
                          </div>
                        </s.TextDescription>

                        <s.SpacerSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledButton
                            id="mintButton"
                            className="connectButton"
                            disabled={!isReady || claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              getData();
                            }}
                          >
                            {claimingNft ? (
                              <div class="loader">Loading...</div>
                            ) : (
                              "MINT"
                            )}
                          </StyledButton>
                        </s.Container>
                      </>
                    )}
                  </>
                )}
                <s.SpacerMedium />
              </s.Container>
            </s.Container>
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
              className="thirdContainer"
            >
              <StyledImg
                alt={"example"}
                src={"/config/images/5.png"}
                style={{ marginBottom: "50px" }}
              />
              <StyledImg
                alt={"example"}
                src={"/config/images/2.png"}
                style={{ transform: "scaleX(-1)" }}
              />
            </s.Container>
          </div>

          <div
            className="socialsBottom"
            style={{
              marginTop: -30
            }}
          >
            <a href="https://opensea.io/collection/pathogenlab" target="_blank">
              <StyledWeb alt={"logo"} src={"/config/images/sea2.png"}   className="openup"/>
            </a>
          </div>

          <s.Container flex={6} ai={"center"} className="stakeEarnContainer contestAccordion" >
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span style={{ color: "red" , fontSize:"22px" }}>
                      LAST 127 NFTS $2000 MINT CONTEST
                    </span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
               
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </s.Container>

          <s.Container flex={6} ai={"center"} className="stakeEarnContainer">
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Passive Income System
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ fontWeight: "bold" }}>
                    Unlock the Potential of Your Virus!
                  </p>
                  <p>
                    By holding a Pathogen Lab NFT, you can earn a passive income
                    ranging from a minimum of 0.003 ETH to a maximum of 0.015
                    ETH per month. This earning potential is determined by the
                    spreading power of your virus, a unique attribute visible in
                    your NFT's metadata. Your chance to mint a high-earning
                    virus is entirely up to luck! Passive income payments will
                    continue at this rate until February 2026.
                  </p>

                  <br />
                  <br />

                  <p>
                    <p style={{ fontWeight: "bold" }}>The Power of Holding</p>
                    Imagine your virus becoming more potent over time. As you
                    hold your NFT, we continuously enhance its power, making it
                    easier for you to infect the world! The only requirement is
                    to hold your NFT and refrain from listing it for sale on any
                    exchange. The more you hold, the more your virus evolves and
                    thrives!
                  </p>

                  <br />

                  <p>
                    <p style={{ fontWeight: "bold" }}>
                      Weekly Payments, Seamless Experience
                    </p>
                    Enjoy weekly passive income payments. Every 7 days,
                    one-quarter of your monthly earnings will be automatically
                    transferred to your wallet. No additional actions are
                    needed—simply hold your NFT to receive these payments. The
                    process is entirely automated, ensuring you receive your
                    rewards effortlessly every week.
                  </p>

                  <br />

                  <p>
                    <p style={{ fontWeight: "bold" }}>Scale Your Earnings</p>
                    There is no limit to the number of NFTs you can hold in the
                    passive income system. The more NFTs you own, especially
                    those with higher spreading power, the greater your passive
                    income will be. Begin earning immediately after minting your
                    NFT—no need to wait for additional minting phases. The
                    moment you mint, the 7-day cycle begins, and your first
                    payment will follow shortly after.
                  </p>

                  <br />

                  <p>
                    Join us on this journey to financial empowerment and watch
                    your virus conquer the globe. Your NFT is not just a digital
                    asset; it's a gateway to consistent earnings and strategic
                    growth.
                  </p>

                  <br />

                  <p>
                    <p style={{ fontWeight: "bold" }}>
                      Become part of the Pathogen Lab legacy and maximize your
                      passive income potential today!
                    </p>
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </s.Container>

          <div
            className="socialsBottom"
            style={{
              marginTop: 10,
            }}
          >
            <a href="https://opensea.io/collection/pathogenlab" target="_blank">
              <StyledWeb alt={"logo"} src={"/config/images/opensea.png"} />
            </a>
            <a href="https://pathogenlab.net/" target="_blank">
              <StyledWeb alt={"logo"} src={"/config/images/worldwide.png"} />
            </a>
            <a href="https://discord.gg/pathogenlab" target="_blank">
              <StyledSocials alt={"logo"} src={"/config/images/discord.png"} />
            </a>
            <a href="https://x.com/pathogenlab" target="_blank">
              <StyledSocials alt={"logo"} src={"/config/images/twitter.png"} />
            </a>
          </div>
        </ResponsiveWrapper>
      </s.Container>
    </s.Screen>
  );
}

export default App;
